import React, { useState, useLayoutEffect, useMemo, useEffect, useCallback } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { Grid, Button, Box } from '@material-ui/core';
import CarouselStrip from '../../components/carousel-strip/carousel-strip';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import SectionContentFeaturedList from '../../components/section-content-featured-list';
import CardImageTitleDescription from '../../components/card-image-title-description/card-image-title-description';
import pageData from '../../data/summits/2020';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import CustomGrid from '../../components/custom-grid/custom-grid';
import CustomGridItem from '../../components/custom-grid/custom-grid-item';
import SectionDescription from '../../components/section-description/section-description';
import axios from 'axios';
import moment from 'moment';
import AnimatedCard from '../../components/card-image-title-description/animated-card';
import ScrollbarAlwaysOn from '../../components/scrollbar/scrollbar-always-on';
import { useCustomGridBreakpoints } from '../../components/custom-grid/useCustomGridBreakpoints';

const Page_2020 = ({ pageContext: { _partner2020 } }) => {
  const currentYear = 2020;
  // const [filteredSpeaker] = useState(Speakers[currentYear]);
  const [speakers, setSpeakers] = useState([]);
  const [carousels, setCarousels] = useState([]);
  const [albums, setAlbums] = useState([]);
  const [digitalSeries, setDigitalSeries] = useState([]);
  const [isCarouselLoaded, setIsCarouselLoaded] = useState(false);
  // const mainSection = 'mainSection';
  // const digitalSeries = 'digitalSeries';
  const knowledgeHub = 'knowledgeHub';
  const [isShowPartners, setIsShowPartners] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [news, setNews] = useState([]);
  const { isDownXs, isDownSm, isDownMd, isDownLg } = useCustomGridBreakpoints();
  const [isSpeakersShownAll, setIsSpeakersShownAll] = useState(false);

  const toggleViewAllSpeakers = useCallback(() => {
    setIsSpeakersShownAll((value) => !value);
  }, []);

  const speakersSectionData = useMemo(() => {
    if (isSpeakersShownAll) {
      return speakers;
    }
    if (isDownXs) {
      return speakers.slice(0, 3);
    }
    if (isDownSm) {
      return speakers.slice(0, 6);
    }
    if (isDownMd) {
      return speakers.slice(0, 9);
    }
    if (isDownLg) {
      return speakers.slice(0, 12);
    }
    return speakers.slice(0, 12);
  }, [isSpeakersShownAll, speakers, isDownXs, isDownSm, isDownMd, isDownLg]);

  let _partnersCount = 0;

  const resetPageData = useCallback(async () => {
    const [newsResponse] = await Promise.all([axios.get(`${process.env.GATSBY_CMS_API_URL}/news`)]);
    setNews(getPageYearData(newsResponse.data));
  }, []);

  const getPageYearData = (items) => {
    return items.filter((item) => Number(item.year) === YEAR);
  };

  const newsData = useMemo(() => {
    return news.map((news) => ({ ...news, file: [news.file] }));
  }, [news]);

  useLayoutEffect(() => {
    try {
      let _speakersByYearArray = [];
      let _carouselsByYearArray = [];
      let _albumsByYearArray = [];
      let _digitalSeriesByYearArray = [];
      Promise.all([
        axios.get(`${process.env.GATSBY_CMS_API_URL}/speaker`),
        axios.get(`${process.env.GATSBY_CMS_API_URL}/carousel`),
        axios.get(`${process.env.GATSBY_CMS_API_URL}/album`),
        axios.get(`${process.env.GATSBY_CMS_API_URL}/digital-series`),
      ])
        .then((_responses) => {
          if (_responses[0].status === 200) {
            const { data } = _responses[0];
            let count = 0;

            data.forEach((_speaker) => {
              if (_speaker.year === currentYear && count < 15) {
                count++;
                _speakersByYearArray.push(_speaker);
              }
            });

            setSpeakers(_speakersByYearArray.sort((a, b) => a.order - b.order));
          }
          if (_responses[1].status === 200) {
            const { data } = _responses[1];

            data.forEach((_carousel) => {
              if (_carousel.year === currentYear) {
                _carouselsByYearArray.push(_carousel);
              }
            });

            setCarousels(_carouselsByYearArray);
            setIsCarouselLoaded(true);
          }
          if (_responses[2].status === 200) {
            const { data } = _responses[2];

            data.forEach((_album) => {
              if (_album.year === currentYear) {
                _albumsByYearArray.push(_album);
              }
            });

            setAlbums(_albumsByYearArray);
          }

          if (_responses[3].status === 200) {
            const { data } = _responses[3];

            let count = 0;
            data.forEach((_digitalSeries) => {
              if (_digitalSeries.year === '2020' && count < 5) {
                count++;
                _digitalSeriesByYearArray.push(_digitalSeries);
              }
            });

            setDigitalSeries(_digitalSeriesByYearArray);
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    resetPageData();
  }, [resetPageData]);

  return (
    <Layout>
      <SEO lang='en' title='#GMIS2020' />
      <Grid container direction='column'>
        {isCarouselLoaded ? (
          <Grid item>
            <CarouselStrip theVideos={carousels} oldEvent={true} title={pageData['carouselTitle']} />
          </Grid>
        ) : null}
        {/* <Grid item xs={12}>
          <SectionContainerLayoutWithFilter title={pageData[mainSection].title}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Typography variant='body1' color='textSecondary'>
                  {pageData['mainSection'].text}
                </Typography>
                <Box clone my={5}>
                  <CustomLink
                    type={2}
                    external
                    linkText={pageData['mainSection'].ctaText}
                    linkUrl={`${process.env.GATSBY_HOME_URL}pdfs/${pageData['mainSection'].ctaUrl}`}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <ImageContainer filename={pageData['mainSection'].filename} />
              </Grid>
            </Grid>
          </SectionContainerLayoutWithFilter>
        </Grid> */}
        <Grid xs={12} item>
          <SectionDescription
            left={true}
            image={false}
            videoThumbNail='wrap-up-video-thumb'
            url='https://videos.gmisummit.com/hls/gmis-2020-wrap-up-official/stream.m3u8'
            ctaText='DOWNLOAD THE #GMIS2020 OUTCOMES REPORT'
            ctaLink='/pdfs/outcomes-book-gmis-2020.pdf'
            ctaLinkExternal={true}
            title='GMIS2020 VIRTUAL EDITION'
            paragraphText='In April 2019, representatives of the Global Manufacturing and Industrialisation Summit (GMIS) and Deutsche Messe AG announced a strategic partnership to host the third edition of the Summit, #GMIS2020, in Germany alongside Hannover Messe, one of the world’s largest industrial technology trade fairs. #GMIS2020 was initially planned to take place in April 2020 alongside Hannover Messe 2020 under the umbrella of #GermanyConnects – inspired by the country’s leadership in connecting the global manufacturing and industrial community with its transformational digital and technological solutions. In collaboration with Deutsche Messe AG, GMIS established a German Higher Committee for GMIS 2020, comprising members from government, industries, and associations in Germany, who played an advisory role and provided guidance with regards to the #GMIS2020 theme, agenda and opening ceremony, as well as support in creating awareness of the event amongst relevant stakeholders. Under the theme – Glocalisation: Towards Inclusive and Sustainable'
          />
        </Grid>

        <Grid item xs={12}>
          {_partner2020 && _partner2020.length > 0 && isShowPartners && (
            <SectionContainerLayoutWithFilter
              title='GMIS2020 PARTNERS'
              isViewAll
              viewAllText='view all'
              baseLink='/partners'>
              <ScrollbarAlwaysOn width='100%' height='100%' showArrows>
                <Box display='flex'>
                  {_partner2020.map((_category) => {
                    if (_category) {
                      return (
                        _category.partner &&
                        _category.partner.length > 0 && (
                          <>
                            <Box key={_category.code} width={150} mx={1} mb={2}>
                              <img
                                style={{
                                  border: '2px solid #a4c627',
                                }}
                                width='150px'
                                src={
                                  _category.file &&
                                  _category.file.code &&
                                  `${process.env.GATSBY_CMS_API_URL}/static/${_category.file.code}`
                                }
                                alt='category'
                              />
                            </Box>
                            {_category &&
                              _category.partner &&
                              _category.partner.length > 0 &&
                              _category.partner.map((_partner) => {
                                return (
                                  <Box key={_partner.code} width={150} mx={1} mb={2}>
                                    <img
                                      width='150px'
                                      src={
                                        _partner.file &&
                                        _partner.file.code &&
                                        `${process.env.GATSBY_CMS_API_URL}/static/${_partner.file.code}`
                                      }
                                      alt='category'
                                    />
                                  </Box>
                                );
                              })}
                          </>
                        )
                      );
                    } else {
                      _partnersCount++;
                      if (_partnersCount === _partner2020.length) {
                        setIsShowPartners(false);
                      }
                    }
                  })}
                </Box>
              </ScrollbarAlwaysOn>
            </SectionContainerLayoutWithFilter>
          )}
        </Grid>

        <Grid item xs={12}>
          <SectionContainerLayoutWithFilter
            title='GMIS2020 SPEAKERS'
            isViewAll
            viewAllText='View All'
            baseLink='/speakers/2020'>
            <>
              <CustomGrid>
                {speakersSectionData.map((speaker, i) => (
                  <CustomGridItem key={`${speaker.code}${i}`}>
                    <AnimatedCard
                      bio={speaker.bio ? speaker.bio : null}
                      image={speaker.file.code}
                      title={
                        speaker.salutationId === 7 || speaker.salutation.name === 'NULL'
                          ? speaker.firstName + ' ' + speaker.lastName
                          : speaker.salutation.name + ' ' + speaker.firstName + ' ' + speaker.lastName
                      }
                      body={
                        speaker.organization && speaker.organization.length > 1
                          ? speaker.designation + ', ' + speaker.organization
                          : speaker.designation
                      }
                    />
                  </CustomGridItem>
                ))}
              </CustomGrid>
              {!isSpeakersShownAll && speakers.length > speakersSectionData.length && (
                <Box mt={3}>
                  <Button fullWidth variant='contained' color='primary' onClick={toggleViewAllSpeakers}>
                    VIEW MORE SPEAKERS
                  </Button>
                </Box>
              )}
            </>
          </SectionContainerLayoutWithFilter>
        </Grid>

        {/* 
        <Grid item xs={12}>
          <SectionContainerLayoutWithFilter
            title='GMIS2020 KNOWLEDGE HUB'
            isViewAll
            viewAllText='View all'
            baseLink='/knowledge-hub'>
            <SectionContentFeaturedList
              primaryMediaType='image'
              secondaryMediaType='image'
              linkText='Read more'
              data={pageData[knowledgeHub]}
            />
          </SectionContainerLayoutWithFilter>
        </Grid> */}
        <Grid item>
          <SectionContainerLayoutWithFilter
            title='GMIS2020 GALLERY'
            isViewAll
            viewAllText='View All'
            baseLink='/galleries'>
            <Grid container spacing={3}>
              {albums ? (
                albums.map((_album, index) => (
                  <Grid key={index} item xs={12} sm={6} md={3}>
                    <Box mb={12} width='100%'>
                      <CardImageTitleDescription
                        isImgTag
                        image={_album.files.find((_file) => _file.type !== 'video/mp4').code}
                        title={moment(_album.date).format('DD MMM YYYY')}
                        body={_album.title}
                        ctaText={_album.albumType === 'videos' ? 'View Video Library' : 'View Album'}
                        readMore={`/galleries/${_album.year}/${_album.urlSlug}`}
                      />
                    </Box>
                  </Grid>
                ))
              ) : (
                <p>Loading</p>
              )}
            </Grid>
          </SectionContainerLayoutWithFilter>
        </Grid>
        <Grid item xs={12}>
          {newsData.length > 0 ? (
            <Grid item xs={12}>
              <SectionContainerLayoutWithFilter title='GMIS2020 NEWS' isViewAll viewAllText='View all' baseLink='/news'>
                <SectionContentFeaturedList
                  primaryMediaType='image'
                  secondaryMediaType='image'
                  apiData
                  isNews
                  linkText='Read more'
                  data={newsData}
                />
              </SectionContainerLayoutWithFilter>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <SectionContainerLayoutWithFilter title='GMIS2020 DIGITAL SERIES' baseLink='/digital-series' isViewAll>
            <Grid item container xs={12} spacing={3}>
              {digitalSeries.map((_digitalSeries, index) => (
                <Grid item xs={12} lg={4}>
                  <CardImageTitleDescription
                    isImgTag
                    image={
                      _digitalSeries.file[0].type !== 'video/mp4'
                        ? _digitalSeries.file[0].code
                        : _digitalSeries.file[1].code
                    }
                    title={_digitalSeries.title}
                    body={_digitalSeries.description}
                    readMore={`/digital-series/2020/${_digitalSeries.urlSlug}`}
                    lines={6}
                  />
                </Grid>
              ))}
            </Grid>
          </SectionContainerLayoutWithFilter>
          {/* <SectionContainerLayoutWithFilter
            isViewAll
            viewAllText='View All'
            baseLink='/digital-series'
            title='DIGITAL SERIES'>
            {isMobile ? (
              pageData[digitalSeries].map((_data, index) => {
                return (
                  <CardImageTitleDescription
                    image={_data.filename}
                    title={_data.title}
                    body={_data.text}
                    readMore={_data.ctaUrl}
                    ctaText={_data.ctaText}
                    lines={6}
                  />
                );
              })
            ) : (
              <Grid item container xs={12} spacing={3}>
                {pageData[digitalSeries].map((_data, index) => {
                  return (
                    <Grid item xs={12} lg={4} key={index}>
                      <CardImageTitleDescription
                        image={_data.filename}
                        title={_data.title}
                        body={_data.text}
                        readMore={_data.ctaUrl}
                        ctaText={_data.ctaText}
                        lines={6}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
            {/* <Grid item container xs={12} spacing={3}>
              {pageData[digitalSeries].map((_data, index) => {
                return (
                  <Grid item xs={12} lg={4} key={index}>
                    <CardImageTitleDescription
                      image={_data.filename}
                      title={_data.title}
                      body={_data.text}
                      readMore={_data.ctaUrl}
                      ctaText={_data.ctaText}
                      lines={6}
                    />
                  </Grid>
                );
              })}
            </Grid> */}
          {/* </SectionContainerLayoutWithFilter> */}
        </Grid>
      </Grid>
    </Layout>
  );
};

const YEAR = 2020;

export default Page_2020;
